import React from 'react'
import classnames from 'classnames';

import css from './icons/css.svg';
import dynamo from './icons/dynamodb.png';
import email from './icons/email.svg';
import gql from './icons/graphql.svg';
import html from './icons/html.svg';
import instagram from './icons/instagram.svg';
import js from './icons/js.svg';
import link from './icons/link.svg';
import linkedin from './icons/linkedin.svg';
import medium from './icons/medium.svg';
import meteor from './icons/meteor.svg';
import mongo from './icons/mongodb.png';
import mysql from './icons/mysql.png';
import node from './icons/node.svg';
import php from './icons/php.png';
import react from './icons/react.svg';
import redux from './icons/redux.svg';
import skype from './icons/skype.svg';
import sls from './icons/serverless.svg';
import twitter from './icons/twitter.svg';
import vue from './icons/vue.svg';

import './Icon.css'

export default function Icon({
  className = '',
  alt,
  icon,
}) {
  return (
    <div className={classnames('icon-wrapper', className)}>
      <img
        className={classnames('icon', icon)}
        src={{
          css,
          dynamo,
          email,
          gql,
          html,
          instagram,
          js,
          link,
          linkedin,
          medium,
          meteor,
          mongo,
          mysql,
          node,
          php,
          react,
          redux,
          skype,
          sls,
          twitter,
          vue,
        }[icon]}
        alt={alt || icon}
      />
    </div>
  );
}
