import React from 'react';
import styled from 'styled-components';

import Icon from '../components_ui/Icon';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
  /*background-color: var(--color-theme-grey);*/

  .connect-item {
    display: flex;
    color: #000;
    font-weight: 700;
    align-items: center;
    padding: 1rem;
  }

  .connect-item-icon {
    opacity: 7;
  }

  .connect-item-link {
  }

  .connect-item-link-text {
    color: var(--color-primary);
    font-size: 1.8rem;
  }

  .connect-item-icon .icon.link {
    height: 2.2rem;
  }
`;

const data = {
  linkedin: {
    text: 'ivanalcalde',
    href: 'https://www.linkedin.com/in/ivanalcalde',
  },
  medium: {
    text: '@ivanAlcalde',
    href: 'https://medium.com/@ivanAlcalde',
  },
  twitter: {
    text: 'ivanalcalde',
    href: 'https://twitter.com/ivanalcalde',
  },
  instagram: {
    text: 'ivanalcalde10',
    href: 'https://instagram.com/ivanalcalde10',
  },
  skype: {
    text: 'ivanalcalde',
  },
  email: {
    text: 'contacto@ivanalcalde.com',
    href: 'mailto: contacto@ivanalcalde.com',
  },
}

const ConnectItem = ({ children, onlyIcon }) => {
  const { href } = data[children];

  const external = ['email'].includes(children);

  return (
    <a
      className="connect-item"
      href={href}
      {...external ? { target: '_blank', rel: 'noopener noreferrer' } : {}}
    >
      <Icon className="connect-item-icon" icon={children} />
    </a>
  );
}

export default function Connect({ onlyIcon }) {
  return (
    <Wrapper>
      <ConnectItem>linkedin</ConnectItem>
      <ConnectItem>medium</ConnectItem>
      <ConnectItem>twitter</ConnectItem>
      <ConnectItem>instagram</ConnectItem>
      {/*<ConnectItem>skype</ConnectItem>*/}
      <ConnectItem>email</ConnectItem>
    </Wrapper>
  );
}
