import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Carousel from '../components_ui/Carousel';

import dataAboutMe from '../_data/about_me';
import dataAboutMeJson from '../_data/about_me_json';
import dataAboutMeSidebar from '../_data/about_me_sidebar';

const Wrapper = styled.div`
  .Me-sidebar-title {
    font-size: 2.2rem;
    letter-spacing: .2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--color-primary);
  }

  .Me-sidebar-content {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.2rem;
    color: var(--color-theme-grey);
    text-align: justify;
    hyphens: auto;
    margin-top: 1rem;
    color: #FFF;
  }

  .carousel-wrapper {
    margin-top: 20px;

    .json-toggle {
      font-size: 3.4rem;
      font-weight: 700;
      color: var(--color-theme-grey);
      cursor: pointer;
      text-align: center;
      margin-bottom: 10px;
    }

    .json-toggle:hover {
      color: var(--color-primary);
    }

    .json-toggle.selected {
      color: var(--color-primary);
    }

    .Me-content {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 2.2rem;
        max-width: 80%;
        color: var(--color-primary);
      }

      p {
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 700;
        hyphens: auto;
        line-height: 3.2rem;
        text-align: justify;
        max-width: 60rem;
        margin-top: 1.7rem;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-top: .4rem;

        li {
          color: var(--color-primary);
          background-color: var(--color-theme-grey);
          padding: .4rem 1rem;
          font-size: 1.5rem;
          border-radius: 5px;
          margin: .7rem .7rem 0 0;
        }
      }
    }

    .Me-json {
      font-size: 1.8rem;
      padding: 3rem 4rem;
      width: 100%;
    }

    .Me-json-open, .Me-json-close {
      font-weight: 700;
    }

    .Me-json-open::after {
      content: "{";
    }

    .Me-json-open.Me-json-aboutme.en::after {
      content: "aboutMe: {";
    }

    .Me-json-open.Me-json-aboutme.es::after {
      content: "sobreMi: {";
    }

    .Me-json-close::after {
      content: "}";
    }

    .Me-json-fieldvalue {
      display: flex;
      flex-direction: column;
    }

    .Me-json-field {
      margin-left: 2rem;
      font-weight: 700;
    }

    .Me-json-field::after {
      content: ":";
    }

    .Me-json-value {
      margin-left: 2rem;
      font-weight: 200;
      color: var(--color-primary);
    }

    .Me-json-value::after {
      content: ",";
    }
  }
`;

const AboutmeSidebar = ({ lang }) => (
  <React.Fragment>
    <h2 className="Me-sidebar-title">{dataAboutMeSidebar[lang].title}</h2>
    { dataAboutMeSidebar[lang].paragraphs.map((content, index) => (
      <p key={index} className="Me-sidebar-content">{content}</p>
    ))}
  </React.Fragment>
);

export default class Aboutme extends Component {
  state = {
    selectedContent: 0,
    json: false,
  }

  _toggleJson = () => this.setState({
    json: !this.state.json,
  })

  render() {
    const { lang } = this.props;
    const { json, selectedContent } = this.state;
    const aboutMe = dataAboutMe[lang][selectedContent];

    return (
      <Wrapper>
        <AboutmeSidebar lang={lang} />
        <div className="carousel-wrapper">
          <div
            className={classnames('json-toggle', { selected: json })}
            onClick={this._toggleJson}
          >
            {`{ format: json }`}
          </div>
          {!json &&
            <Carousel
              handleSelect={index => this.setState({ selectedContent: index })}
              count={dataAboutMe[lang].length}
              selected={selectedContent}
            >
              <div className="Me-content">
                {aboutMe.map(({ type, value }, index) => {
                  var key = index;
                  if (type === 'title') {
                    return (
                      <h1 key={key}>{value}</h1>
                    );
                  }
                  if (type === 'paragraph') {
                    return (
                      <p key={key}>{value}</p>
                    );
                  }
                  if (type === 'list') {
                    return (
                      <ul key={key}>
                        {value.map((text, index) => (
                          <li key={index}>{text}</li>
                        ))}
                      </ul>
                    );
                  }
                  return null;
                })}
              </div>
            </Carousel>
          }
          {json &&
            <div className="Me-json">
              <div className={`Me-json-open Me-json-aboutme ${lang}`} />
                {dataAboutMeJson.map(attr => (
                  <div className="Me-json-fieldvalue">
                    <div className="Me-json-field">{attr[lang].field}</div>
                    <div
                      style={attr[lang].style || {}}
                      className="Me-json-value"
                    >
                      { (attr[lang].link &&
                        <a href={attr[lang].value}>{attr[lang].value}</a>) ||
                        attr[lang].value
                      }
                    </div>
                  </div>
                ))}
              <div className="Me-json-close" />
            </div>
          }
        </div>
      </Wrapper>
    );
  }
}
