import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Section from './Section';
import SectionTitle from './SectionTitle';

const Wrapper = styled.div`
  position: relative;

  .toggle-color {
    background-color: var(--color-theme-grey);
    border-radius: 5px;
    opacity: .6;
    padding: 1rem;
    cursor: pointer;
    position: fixed;
    right: 25px;
    bottom: 60px;
    font-size: 3.4rem;
    min-width: 220px;
    text-align: center;
    color: ${({ color }) => color ? 'var(--color-primary)' : '#FFF' };
    &:hover {
      color: var(--color-primary);
    }
    z-index: 100;
  }

  .photos-featured {
    display: flex;
    justify-content: center;
    margin: 0 0 20px 0;

    .photo {
      width: 80%;
      min-width: 320px;
      max-width: 1000px;
    }
  }

  .photos-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .photo {
    width: 25%;
    min-width: 320px;
    height: 550px;
    background-repeat: no-repeat;
    background-position: center center;
    filter: grayscale();
    filter: ${({ color }) => color ? 'none' : 'grayscale()' };
    margin: 10px;
    border: 2px solid var(--color-primary);
  }

  .photo:hover {
    filter: none;
    transition: .7s;
  }
`;

const Photo = ({
  className,
  bgpos,
  h,
  w,
  src,
}) => (
  <div
    className={classnames('photo', className)}
    style={{
      backgroundImage: `url(${src})`,
      backgroundPosition: bgpos,
      height: h,
      width: w,
    }}
  />
)

class Photos extends Component {
  state = {
    color: false,
  }

  _toggleColor = () => this.setState({ color: !this.state.color })

  render() {
    return (
      <Wrapper color={this.state.color}>
        <Section>
          <SectionTitle>{{ es: 'Fotos', en: 'Photos' }[this.props.lang]}</SectionTitle>
          <div className="photos-featured">
            <Photo h={240} src="gallery/photo_08.jpg" />
          </div>
          <div
            className="toggle-color"
            onClick={this._toggleColor}
          >
            {`{ color: ${this.state.color }}`}
          </div>
          <div className="photos-gallery">
            <Photo bgpos={`55% 25%`} src="gallery/photo_02.jpg" />
            <Photo bgpos={`50% 32%`} src="gallery/photo_05.jpg" />
            <Photo src="gallery/photo_10.jpg" />
            <Photo bgpos={`65% 68%`} src="gallery/photo_09.jpg" />
            <Photo src="gallery/photo_01.jpg" />
            <Photo bgpos={`55% 0%`} src="gallery/photo_06.jpg" />
            <Photo bgpos={`65% 32%`} src="gallery/photo_16.jpg" />
            <Photo src="gallery/photo_11.jpg" />
            <Photo src="gallery/photo_07.jpg" />
            <Photo bgpos={`51% 25%`} src="gallery/photo_17.jpg" />
            <Photo bgpos={`36% 44%`} src="gallery/photo_15.jpg" />
            <Photo bgpos={`70% 40%`} src="gallery/photo_04.jpg" />
            <Photo bgpos={`75% 15%`} src="gallery/photo_18.jpg" />
            <Photo bgpos={`35% 25%`} src="gallery/photo_12.jpg" />
            <Photo bgpos={`54% 25%`} src="gallery/photo_13.jpg" />
          </div>
        </Section>
      </Wrapper>
    );
  }
}

export default Photos;
