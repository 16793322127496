import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  color: var(--color-theme-grey);

  .highlighted {
    color: var(--color-primary);
  }

  .header-title {
    font-size: 2.8rem;
    text-transform: uppercase;

    .logo {
      font-size: 4.2rem;
      letter-spacing: -.5rem;
      margin-right: 2rem;
    }

    .header-title-brand {
      display: inline-block;
    }
  }
`;

export default function Header() {
  return (
    <Wrapper>
      <h1 className="header-title">
        <span className="logo highlighted">{'> | <'}</span>
        <div className="header-title-brand">
          <span className="highlighted">_ IV</span>
          <span>AN ALCALDE</span>
        </div>
      </h1>
    </Wrapper>
  );
}
