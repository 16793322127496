export default {
  en: [
    [
      {
        type: 'title',
        value: 'Full-Stack JavaScript Developer at Double_Ok',
      },
      {
        type: 'paragraph',
        value: 'Nowadays I\'m working as Full-Stack JavaScript Developer at Double_Ok. We develop Cross-Platform applications (web/mobile) and our current "Frontend Stack" includes:',
      },
      {
        type: 'list',
        value: ['ReactJS', 'React Native', 'JavaScript', 'Html', 'CSS', 'GraphQL'],
      },
      {
        type: 'paragraph',
        value: 'Our Databases are usually NoSQL. We are normally using:',
      },
      {
        type: 'list',
        value: ['MongoDb', 'DynamoDb'],
      },
      {
        type: 'paragraph',
        value: 'We deploy applications on AWS and our current "Backend Stack" includes:',
      },
      {
        type: 'list',
        value: ['NodeJS', 'Serverless', 'AWS', 'GraphQL', 'JavaScript'],
      },
    ],
    [
      {
        type: 'title',
        value: 'NHA',
      },
      {
        type: 'paragraph',
        value: 'They are my driving force, I love having fun, and hanging out with them as much as possible... although sometimes I think to myself: I would like to spend more time with them.',
      },
      {
        type: 'paragraph',
        value: '"You guys are the best, I love you madly…sometimes I wonder how I got to be so damn lucky... and sometimes I feel sorry for the time we are not together... forgive me when I work long hours."',
      },
      {
        type: 'list',
        value: ['Natalia', 'Héctor', 'Ander'],
      },
    ],
    [
      {
        type: 'title',
        value: 'Sports and Hobbies',
      },
      {
        type: 'paragraph',
        value: 'My favorite sport is football, no doubt about it. I have been kicking a ball until I turned 35 years old... almost every day.',
      },
      {
        type: 'paragraph',
        value: 'At present, I do sport daily because I can´t imagine life without it.',
      },
      {
        type: 'list',
        value: ['Fútbol', 'Real Madrid', 'Oliver y Benji', '10', 'Diego Maradona'],
      },
      {
        type: 'paragraph',
        value: 'I love going to the cinema and I love watching tv series, too... like everyone else :). Here are some of my favorite ones:',
      },
      {
        type: 'list',
        value: ['Vikings', 'Sons of Anarchy', 'Avatar', 'Rocky Balboa', 'Pulp Fiction', 'Martin Scorsese', 'Quentin Tarantino'],
      },
    ],
  ],
  es: [
    [
      {
        type: 'title',
        value: 'Desarrollador Full-Stack JavaScript en Double_Ok',
      },
      {
        type: 'paragraph',
        value: 'Actualmente trabajo como Desarrollador Full-Stack JavaScript en la empresa Double_Ok. Desarrollamos proyectos multiplataforma (web/mobile) y nuestro "Stack Frontend" actualmente suele incluir:',
      },
      {
        type: 'list',
        value: ['ReactJS', 'React Native', 'JavaScript', 'Html', 'CSS', 'GraphQL'],
      },
      {
        type: 'paragraph',
        value: 'Normalmente usamos bases de datos NoSQL como:',
      },
      {
        type: 'list',
        value: ['MongoDb', 'DynamoDb'],
      },
      {
        type: 'paragraph',
        value: 'Nuestras aplicaciones en la parte del servidor las desplegamos en AWS y nuestro "Stack Backend" suele incluir:',
      },
      {
        type: 'list',
        value: ['NodeJS', 'Serverless', 'AWS', 'GraphQL', 'JavaScript'],
      },
    ],
    [
      {
        type: 'title',
        value: 'NHA',
      },
      {
        type: 'paragraph',
        value: 'Son mi motor, me encanta disfrutar con ellos todo el tiempo que puedo... aunque a veces no sea todo el que me gustaría :(',
      },
      {
        type: 'paragraph',
        value: '"Sois los mejores y os quiero con locura... perdonadme a veces por el tiempo que no estoy con vosotros por andar aporreando el teclado"',
      },
      {
        type: 'list',
        value: ['Natalia', 'Héctor', 'Ander'],
      },
    ],
    [
      {
        type: 'title',
        value: 'Deporte y Aficiones',
      },
      {
        type: 'paragraph',
        value: 'El fútbol es sin duda mi deporte favorito. Hasta mis 35 años estuve acompañado de un balón practicamente cada día.',
      },
      {
        type: 'paragraph',
        value: 'Actualmente sigo practicando deporte a diario porque no entiendo la vida de otra forma.',
      },
      {
        type: 'list',
        value: ['Fútbol', 'Real Madrid', 'Oliver y Benji', '10', 'Diego Maradona'],
      },
      {
        type: 'paragraph',
        value: 'Me encanta ir al cine y ver series, como a todo el mundo :) . Mis favoritos:',
      },
      {
        type: 'list',
        value: ['Vikingos', 'Los Hijos de la Anarquía', 'Avatar', 'Rocky Balboa', 'Pulp Fiction', 'Martin Scorsese', 'Quentin Tarantino'],
      },
    ],
  ],
}
