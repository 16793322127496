import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import Carousel from '../components_ui/Carousel';

import dataTimeline from '../_data/timeline';

const Wrapper = styled.div`
  .period {
    font-size: 2.7rem;
    font-weight: 200;
    color: var(--color-primary);
  }

  .period.first.en:before {
    content: "since ";
  }

  .period.first.es:before {
    content: "desde ";
  }

  .jobtitle {
    color: #FFF;
    font-size: 1.8rem;
    font-weight: 200;
    margin-bottom: .5rem;
  }

  .company {
    color: var(--color-primary);
    font-size: 1.7rem;
    opacity: .7;
    margin-bottom: 1rem;
  }

  .jobdescription {
    color: #FFF;
    font-size: 1.5rem;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .tag {
    color: var(--color-primary);
    background-color: var(--color-theme-grey);
    padding: .4rem 1rem;
    font-size: 1.5rem;
    border-radius: 5px;
    margin: .7rem .7rem 0 0;
  }
`;

export default class Timeline extends Component {
  state = {
    selected: 0,
  }

  static defaultProps = {
    lang: 'en',
    data: [],
  }

  _select = selected => this.setState({ selected })

  render() {
    const { selected } = this.state;
    const { lang } = this.props;

    const {
      company,
      jobDescription,
      jobTitle,
    } = dataTimeline[selected].data[lang];

    const {
      tags,
    } = dataTimeline[selected].data;

    return (
      <Wrapper>
        <Carousel
          count={dataTimeline.length}
          selected={selected}
          handleSelect={this._select}
        >
          <div className={classnames('timeline-content', `year-${selected}`)}>
            <h3 className={classnames('period', lang, { first: selected === 0 })}>
              {dataTimeline[selected].year}
            </h3>
            <h1 className="jobtitle">{jobTitle}</h1>
            <h2 className="company">{company}</h2>
            <p className="jobdescription">{jobDescription}</p>
            <div className="tags">
              {tags.map(tag => (
                <div className="tag">{tag}</div>
              ))}
            </div>
          </div>
        </Carousel>
      </Wrapper>
    );
  }
}
