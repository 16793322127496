export default [
  {
    en: { field: 'fullName', value: 'Iván Alcalde García' },
    es: { field: 'nombre', value: 'Iván' },
  },
  {
    en: { field: 'dateOfBirth', value: 'March 22, 1979 (02:10 am)' },
    es: { field: 'fechaDeNacimiento', value: '22 de Marzo, 1979 (02:10 am)' },
  },
  {
    en: { field: 'family', value: "['Natalia', 'Héctor', 'Ander']" },
    es: { field: 'familia', value: "['Natalia', 'Héctor', 'Ander']" },
  },
  {
    en: { field: 'favoriteColor', value: '#FF0000', style: { color: '#FF0000' } },
    es: { field: 'colorFavorito', value: '#FF0000', style: { color: '#FF0000' } },
  },
  {
    en: { field: 'favoriteNumber', value: 10 },
    es: { field: 'numeroFavorito', value: 10 },
  },
  {
    en: { field: 'favoriteMeal', value: 'Beef steak' },
    es: { field: 'platoFavorito', value: 'Chuletón' },
  },
  {
    en: { field: 'favoriteSport', value: 'Football' },
    es: { field: 'deporteFavorito', value: 'Fútbol' },
  },
  {
    en: { field: 'favoriteTeam', value: 'Real Madrid' },
    es: { field: 'equipoFavorito', value: 'Real Madrid' },
  },
  {
    en: { field: 'favoriteSerie', value: 'Vikings' },
    es: { field: 'serieFavorita', value: 'Vinkingos' },
  },
  {
    en: { field: 'company', value: 'Double_Ok' },
    es: { field: 'empresa', value: 'Double_Ok' },
  },
  {
    en: { field: 'connect', value: 'Take a look on the footer !' },
    es: { field: 'conecta', value: '¡ Echa un vistazo al pié de página !' },
  },
];
