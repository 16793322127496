import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: 50px;

  .carousel-positions {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    .carousel-selector {
      background-color: var(--color-theme-grey);
      cursor: pointer;
      margin-right: .7rem;
      width: 1rem;
      height: 3.4rem;
    }

    .carousel-selector:hover {
      background-color: var(--color-primary);
    }

    .carousel-selector.selected {
      background-color: var(--color-primary);
    }
  }

  .carousel-prev-next {
    display: flex;
    font-size: 4.8rem;
    font-weight: 700;
    position: absolute;
    top: -19px;
    left: 0;
    color: var(--color-theme-grey);

    .carousel-prev-next-separator:after {
      content: "|";
    }

    .carousel-prev:after {
      content: "<";
    }

    .carousel-next:after {
      content: ">";
    }
  }

  .carousel-content {
  }
`;

export default function Carrousel({
  children,
  handleSelect,
  count,
  selected,
}) {
  return (
    <Wrapper>
      <div className="carousel-prev-next">
        <div className="carousel-next"
          onClick={() => {
            if (selected === (count - 1)) return;
            handleSelect(selected + 1);
          }}
        />
        <div className="carousel-prev-next-separator" />
        <div className="carousel-prev"
          onClick={() => {
            if (selected === 0) return;
            handleSelect(selected - 1);
          }}
        />
      </div>
      <div className="carousel-positions">
        {_.range(count).map(index => (
          <div
            key={index}
            className={classnames('carousel-selector', { selected: index === selected })}
            onClick={() => handleSelect(index)}
          />
        ))}
      </div>
      <div className="carousel-content">
        {children}
      </div>
    </Wrapper>
  )
}
