export default {
  en: {
    title: '_Daddy',
    paragraphs: [
      'Year 2012, the most exciting adventure begins with Héctor, in 2015 Ander came into play.',
      'From that moment on everything changes, our home hardly ever is tidy, we start to apreciate words like "free time", "silence", "movie & popcorn"... sometimes it is not easy, but the journey is definitely worthwhile.',
      'Every honest look , is a gift, when they are with us we always come back down to earth ... At the end of the day they are the ones that save us and make us prize what really matters.',
    ],
  },
  es: {
    title: '_Papi',
    paragraphs: [
      'Año 2012, comienza la verdadera aventura con Héctor, en 2015 llegará Ander.',
      'Desde ese preciso instante todo cambia, la casa ya nunca estará ordenada, empezamos a apreciar verdaderamente palabras como "tiempo libre", "silencio", "cine & palomitas"... habrá momentos que no será nada fácil, pero el viaje merecerá la pena.',
      'Cada mirada sincera que ellos te regalan, cada vez que te hacen sentir presente y te traen de vuelta corrigiendo tu errática trayectoria de adulto... ellos son los que al final del día nos salvan y te hacen valorar lo que verdaderamente importa.',
    ],
  }
}
