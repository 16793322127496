import _ from 'lodash';
import React, { Component } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const LINES = [
  'Full-Stack',
  'JAVASCRIPT',
  'Developer'
];

const Wrapper = styled.div`
  .intro {
    display: flex;
    width: 100%;
  }

  .intro-left, .intro-right {
    display: flex;
    width: 50%;
    flex: 1;
  }

  .intro-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: var(--color-theme-grey-dark);
    font-size: 22rem;
    font-weight: 700;
    letter-spacing: -2.4rem;
  }

  .intro-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12rem;
    background-color: var(--color-theme-grey-dark);
    color: var(--color-theme-grey);
  }

  .intro-title {
    font-size: 4rem;
    font-weight: 400;
  }

  .intro-title:before {
    content: "_ IVAN ";
    color: var(--color-primary);
  }

  .intro-subtitle {
    font-size: 8rem;
    font-weight: 600;
    color: var(--color-theme-grey);
  }

  .intro-subtitle.line2 {
    color: var(--color-primary);
  }

  .intro-subtitle.selected:after {
    content: " _";
    animation: blink 1s step-start 0s infinite;
    -webkit-animation: blink 1s step-start 0s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0.0;
    }
  }

  @-webkit-keyframes blink {
    50% {
      opacity: 0.0;
    }
  }

  @media (max-width: 1024px) {
    .intro-left {
      flex: 1;
    }
    .intro-right {
      flex: 2;
      padding-left: 9rem;
    }
    .intro-logo {
      font-size: 10rem;
      letter-spacing: -1rem;
    }
  }

  @media (max-width: 750px) {
    .intro-left {
      flex: 1;
      align-items: flex-start;
    }
    .intro-right {
      flex: 4;
      padding-left: 7rem;
    }
    .intro-logo {
      font-size: 5rem;
      letter-spacing: -.7rem;
    }
    .intro-title {
      font-size: 3rem;
    }
    .intro-subtitle {
      font-size: 6rem;
    }
  }

  @media (max-width: 500px) {
    .intro-left {
      display: none;
    }
    .intro-right {
      padding-left: 2rem;
    }
    .intro-title {
      font-size: 2.7rem;
    }
    .intro-subtitle {
      font-size: 5rem;
    }
  }
`;

export default class Intro extends Component {
  state = {
    currentLine: 0,
    currentChar: 0,
    lines: ['', '', ''],
  }

  static defaultProps = {
    printCharSpeed: 200,
  }

  componentDidMount() {
    this.setState({ height: window.innerHeight });
    setTimeout(() => this._printChar(), 500);
  }

  _printChar() {
    const { currentLine, currentChar } = this.state;
    const char = _.get(LINES, `${currentLine}.${currentChar}`);

    if (char) {
      var lines = [...this.state.lines];
      lines[currentLine] += char;

      this.setState({ lines }, () => {
        var { currentLine, currentChar } = this.state;
        if (this.state.lines[currentLine] === LINES[currentLine]) {
          currentLine ++;
          currentChar = 0;
        } else {
          currentChar ++;
        }
        setTimeout(
          () => this.setState({ currentLine, currentChar }, this._printChar),
        this.props.printCharSpeed);
      });
    } else {
      setTimeout(this.props.setIntroOff, 3000);
    }
  }

  render() {
    const {
      currentLine,
      lines,
      height,
    } = this.state;

    return (
      <Wrapper>
        <div
          id={this.props.id}
          style={{ height }}
          className={classnames('intro', this.props.className)}
        >
          <div className="intro-left">
            <div className="intro-logo">{`> | <`}</div>
          </div>
          <div className="intro-right">
            <div className="intro-title">ALCALDE</div>
            <div className={classnames([
              'intro-subtitle',
              'line1',
              { selected: currentLine === 0 },
            ])}>
              {lines[0]}
            </div>
            <div className={classnames([
              'intro-subtitle',
              'line2',
              { selected: currentLine === 1 },
            ])}>
              {lines[1]}
            </div>
            <div className={classnames([
              'intro-subtitle',
              'line3',
              { selected: currentLine >= 2 },
            ])}>
              {lines[2]}
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
