import React from 'react';
import styled from 'styled-components';

import Icon from '../components_ui/Icon';

const Wrapper = styled.div`
  .devstack-container {
    margin-bottom: 1rem;
  }

  .devstack-title {
    color: #FFF;
    font-size: 1.8rem;
    opacity: .8;
    /*text-shadow: rgba(0,255,223,.2) 1px 1px 0, rgba(0,255,223,.2) 2px 2px 0;*/
  }

  .devstack {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    width: 32rem;

    .devstack-icons {
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: .4rem;
      background-color: var(--color-grey-dark-1);
      min-width: 33%;

      .devstack-icon {
        opacity: .7;
        margin: .7rem;
      }
    }
  }
`;

const DevStackItem = ({
  icons,
  title,
}) => (
  <div className="devstack-container">
    <h3 className="devstack-title">{title}</h3>
    <div className="devstack">
      <div className="devstack-icons">
        {icons.map(icon => <Icon className="devstack-icon" icon={icon} />)}
      </div>
    </div>
  </div>
);

export default function DevStack({
  frontend,
  backend,
  db,
  learning,
  lang,
}) {
  const stack = {
    backend: ['js', 'gql', 'node', 'sls'],
    frontend: ['js', 'html', 'css', 'react', 'redux', 'gql'],
    db: ['mongo', 'dynamo'],
    learning: ['vue'],
  };
  return (
    <Wrapper>
      <DevStackItem title="Frontend" icons={stack.frontend} />
      <DevStackItem title="Backend" icons={stack.backend} />
      <DevStackItem
        title={{
          en: 'Databases',
          es: 'Bases de datos',
        }[lang]}
        icons={stack.db}
      />
      <DevStackItem
        title={{
          en: 'Learning',
          es: 'Aprendiendo',
        }[lang]}
        icons={stack.learning}
      />
    </Wrapper>
  );
}
