import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';
import styled from 'styled-components';

import App from './App';

import Header from './components/Header';
import ConnectToolBar from './components/ConnectToolBar';
import LangToolBar from './components/LangToolBar';
import Intro from './components/Intro';
import Photos from './components/Photos';

import Separator from './components_ui/Separator';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .app-top-bar {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 80%;
      background-color: var(--color-theme-grey);

      div {
        font-size: 1.8rem;
        font-weight: 700;
        color: var(--color-theme-grey-dark);
        cursor: pointer;
        margin: 0 1rem 0 0;
        color: #FFF;
        opacity: .9;

        &:hover {
          color: var(--color-primary);
        }

        &::before {
          content: "{ _";
        }
        &::after {
          content: " }";
        }
      }
    }

    .lang-selectors {
      background-color: var(--color-theme-grey-dark);
      width: 20%;

      * {
        border-right: 1px solid var(--color-theme-grey);
        cursor: pointer;
      }
    }
  }

  .app {
    padding-top: 40px;
    .content-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .content {
        padding: 2rem;
        max-width: 1024px;
      }
    }


    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: var(--color-theme-grey-dark);
    }
  }

  @media (min-width: 700px) {
    .effect-on-hover-unveil {
      opacity: .5;
    }

    .effect-on-hover-unveil:hover {
      opacity: 1;
      transition: opacity .7s;
    }
  }
`;

class AppRouter extends Component {
  state = {
    lang: 'es',
    intro: false,
  }

  _selectLang = lang => this.setState({ lang })

  _setIntroOn = () => {
    this.setState({ intro: true });
  }

  _setIntroOff = () => {
    setTimeout(() => (
      this.setState({ intro: false })
    ), 1000);
  }

  render() {
    const {
      lang,
      intro,
    } = this.state;

    const introTxt = { es: 'intro', en: 'intro' }[lang];
    const photosTxt = { es: 'fotos', en: 'photos' }[lang];
    const homeTxt = { es: 'inicio', en: 'home' }[lang];

    return (
      <Router>
        <Wrapper>
          <div className="app-top-bar">
            <div className="buttons">
              <div onClick={this._setIntroOn}>{introTxt}</div>
              <div>
                <Link to='/'>{homeTxt}</Link>
              </div>
              <div>
                <Link to='/photos'>
                  {photosTxt}
                </Link>
              </div>
            </div>
            <div className="lang-selectors">
              <LangToolBar
                langSelected={lang}
                selectLang={this._selectLang}
              />
            </div>
          </div>
          {intro &&
            <Intro
              setIntroOff={this._setIntroOff}
            />
          }
          {!intro &&
            <div className="app">
              <Header />
              <div className="content-wrapper">
                <div className="content">
                  <Route path="/" exact component={() => <App lang={lang} />} />
                  <Route path="/photos" exact component={() => <Photos lang={lang} />} />
                </div>
              </div>
              <div className="footer">
                <Separator />
                <ConnectToolBar />
              </div>
            </div>
          }
        </Wrapper>
      </Router>
    );
  }
}

export default AppRouter;
