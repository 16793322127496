import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  .lang-selector {
    flex: 1;
    text-align: center;
    color: #FFF;
    font-size: 2rem;

    &.selected, &:hover {
      color: var(--color-primary);
    }
  }
`;

const LangSelector = ({
  children,
  selectLang,
  selected,
}) => {
  return (
    <div
      className={classnames('lang-selector', {
        selected,
      })}
      onClick={() => selectLang(children)}
    >
      {children}
    </div>
  );
}

export default function LangToolBar({
  langSelected,
  selectLang,
}) {
  return (
    <Wrapper>
      <LangSelector selectLang={selectLang} selected={langSelected === 'es'}>
        es
      </LangSelector>
      <LangSelector selectLang={selectLang} selected={langSelected === 'en'}>
        en
      </LangSelector>
    </Wrapper>
  );
}
