import styled from 'styled-components';

const SectionTitle = styled.h1`
  font-size: 3.2rem;
  color: var(--color-primary);
  margin-bottom: 2rem;
  text-align: center;
  border-radius: 5px;
  background-color: var(--color-theme-grey);

  &::before {
    content: '_ ';
    animation: blink 1s step-start 0s infinite;
    -webkit-animation: blink 1s step-start 0s infinite;
  }
`;

export default SectionTitle;
