import React, { Component } from 'react';
import styled from 'styled-components';

import Section from './components/Section';
import SectionTitle from './components/SectionTitle';
import DevStack from './components/DevStack';
import Timeline from './components/Timeline';
import Aboutme from './components/Aboutme';

const Wrapper = styled.div`
`;

class App extends Component {
  render() {
    const { lang } = this.props;

    return (
      <Wrapper>
        <Section>
          <SectionTitle>
            {{
              es: 'Cronología',
              en: 'Timeline',
            }[lang]}
          </SectionTitle>
          <Timeline lang={lang} />
        </Section>

        <Section>
          <SectionTitle>
            {{
              es: 'Sobre mí',
              en: 'About me',
            }[lang]}
          </SectionTitle>
          <Aboutme lang={lang} />
        </Section>

        <Section>
          <SectionTitle>Stack</SectionTitle>
          <DevStack lang={lang} />
        </Section>
      </Wrapper>
    );
  }
}

export default App;
