export default [
  {
    year: '2015',
    data: {
      tags: ['javascript', 'graphql', 'node', 'serverless', 'html', 'css', 'react', 'react-native', 'redux', 'mongodb', 'dynamodb'],
      en: {
        company: 'double_ok',
        companyLink: 'https://www.doubleok.com',
        jobTitle: 'Full-Stack JavaScript Developer',
        jobDescription: 'Development of Web and Mobile Applications on the Frontend. Design and architecture of APIs as well as data modeling on the Backend.',
      },
      es: {
        company: 'double_ok',
        companyLink: 'https://www.doubleok.com',
        jobTitle: 'Desarrollador Full-Stack JavaScript',
        jobDescription: 'Desarrollo de Aplicaciones Web y Móvil. Diseño y arquitectura de APIs, así como el modelado de datos en la parte del Servidor.',
      }
    },
  },
  {
    year: '2013 - 2015',
    data: {
      tags: ['javascript', 'node', 'html', 'css', 'meteor', 'mongodb'],
      en: {
        company: 'Freelance',
        jobTitle: 'Full-Stack JavaScript Developer',
        jobDescription: 'Development of Web Applications 100% in JavaScript (frontend and backend) using the framework MeteorJS.',
      },
      es: {
        company: 'Freelance',
        jobTitle: 'Desarrollador Full-Stack JavaScript',
        jobDescription: 'Desarrollo de Aplicaciones Web 100% en JavaScript (cliente y servidor) usando el "framework" MeteorJS.',
      }
    },
  },
  {
    year: '2009 - 2013',
    data: {
      tags: ['php', 'javascript', 'html', 'css', 'mysql', 'lamp'],
      en: {
        company: 'DylMedia',
        companyLink: 'https://www.linkedin.com/company/dylworld/',
        jobTitle: 'Web Developer (LAMP)',
        jobDescription: 'Development of Web Applications with PHP and MySQL.',
      },
      es: {
        company: 'DylMedia',
        companyLink: 'https://www.linkedin.com/company/dylworld/',
        jobTitle: 'Desarrollador Web (LAMP)',
        jobDescription: 'Desarrollo de Aplicaciones Web con PHP y MySQL.',
      }
    },
  },
  {
    year: '2007 - 2009',
    data: {
      tags: ['php', 'javascript', 'html', 'css', 'mysql', 'lamp'],
      en: {
        company: 'Knet Comunicaciones',
        companyLink: 'https://www.knet.es',
        jobTitle: 'Web Developer (LAMP)',
        jobDescription: 'Development of Web Applications with PHP and MySQL.',
      },
      es: {
        company: 'Knet Comunicaciones',
        companyLink: 'https://www.knet.es',
        jobTitle: 'Desarrollador Web (LAMP)',
        jobDescription: 'Desarrollo de Aplicaciones Web con PHP y MySQL.',
      }
    }
  },
];
